<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
        </div>

    </div>
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header d-block d-sm-flex">
                    <div>
                        <h4 class="card-title">Document Setup</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">

                        <div class="col-md-5 order-md-2 mb-4">
                            <h4 class="d-flex justify-content-between align-items-center mb-3">
                                <span>Form Field </span>

                            </h4>
                            <form class="needs-validation" novalidate="">
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <label for="firstName">Field name</label>
                                        <input type="text" class="form-control" id="fieldName" v-model="newField.name_label" placeholder="Enter the field name" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="lastName">Field type</label>
                                        <v-select :options="types" v-model="selected.field" @input="setSelectedType" label="name_type" :filterable="false">
                                        </v-select>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="lastName">Required</label>
                                        <v-select :options="[{name: 'true', value: true}, {name:'false', value: false}]" v-model="selected.required" @input="val => newField.required = val.value" label="name" :filterable="false">
                                        </v-select>
                                    </div>
                                </div>

                                <label for="lastName">Sequence</label>
                                <div class="input-group">
                                    <input type="number" min="1" class="form-control" placeholder="Sequence" v-model="newField.position">
                                    <div class="input-group-append">
                                        <button type="button" @click="saveFormField" class="btn btn-info" :disabled="!filledFormField">Add Field to Document</button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 mb-3 mt-3" v-if="!is_public">
                                        <label for="users">Select Users Access Document</label>
                                        <v-select label="first_name" multiple :filterable="false" v-model="user" :options="users" @search="onSearchUser">
                                            <template slot="no-options"> type to search users... </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.first_name }} {{ option.last_name }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.first_name }} {{ option.last_name }}
                                                </div>
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-md-7 order-md-1">
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="documentName">Document name</label>
                                    <input type="text" class="form-control" id="documentName" placeholder="Enter the document name" v-model="name_form" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="documentName">Document icon</label>
                                    <b-form-file accept="image/png, image/gif, image/jpeg" v-on:change="onFileChange($event)"></b-form-file>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label for="documentName">Document description</label>
                                    <textarea rows="5" class="form-control" id="documentDescription" placeholder="Enter the document description" v-model="description"></textarea>
                                </div>
                            </div>
                            <h4 class="d-flex justify-content-between align-items-center mb-3">
                                <span> Form Preview </span>
                                <b-form-checkbox id="checkbox-1" v-model="is_public" name="checkbox-1" :value="true" :unchecked-value="false">
                                    Public Doc
                                </b-form-checkbox>
                            </h4>
                            <form class="needs-validation" novalidate="">
                                <div class="row">
                                    <div class="col-sm-12 mb-3" v-for="(value, index) in shelterForm" :key="index">
                                        <label for="firstName">
                                            {{ value.position }}. {{ value.name_label }}
                                            <small class="text-danger" v-if="value.required">*required</small>
                                        </label>
                                        <div class="input-group">

                                            <input v-if="value.type == 'date'" type="date" class="form-control" readonly>
                                            <input v-else-if="value.type == 'datetime'" type="datetime-local" class="form-control" readonly>
                                            <input v-else-if="value.type == 'time'" type="time" class="form-control" readonly>
                                            <b-form-file v-else-if="value.type == 'image'" accept="image/*" size="lg" disabled></b-form-file>
                                            <input v-else type="text" class="form-control" readonly />

                                            <div class="input-group-prepend">
                                                <button type="button" @click="removeFormField(index)" class="btn btn-outline-danger">
                                                    <i class="fa fa-trash"></i> Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr class="mb-4" v-if="filledFormSetup" />
                                <!-- <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <label for="documentName">Document name</label>
                                        <input type="text" class="form-control" id="documentName" placeholder="Enter the document name" v-model="name_form" />
                                    </div>

                                    <div class="col-md-12 mb-5">
                                        <label for="documentName">Document icon</label>
                                        <b-form-file accept="image/png, image/gif, image/jpeg" v-on:change="onFileChange($event)"></b-form-file>
                                    </div>
                                </div> -->
                                <button type="button" v-if="filledFormSetup" class="btn btn-primary btn-lg btn-block" @click="saveDocument">
                                    Save this document
                                </button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "DocumentSetup",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Document Setup | %s',
    },
    data() {
        return {
            selected: {
                type: {},
                required: {
                    name: 'false',
                    value: false
                }
            },
            newField: {
                name_label: "",
                id_type: "",
                type: "",
                position: 1,
                required: false
            },
            types: [],
            user: [],
            users: [],
            shelterForm: [],
            name_form: "",
            description: "",
            is_public: false,
            user_access: null,
            icon_name: "",
            icon_content: ""
        }
    },
    watch: {
        user: function (value) {
            this.user_access = value.map(value => value.id)
        }
    },
    computed: {
        filledFormField: function () {
            if (
                !this.newField.id_type ||
                !this.newField.name_label ||
                !this.newField.position
            ) {
                return false
            }

            return true
        },
        filledFormSetup: function () {
            if (this.shelterForm.length == 0)
                return false

            return true
        }
    },
    async mounted() {
        this.$store.dispatch("setLoading", true);
        axios.get('dynamic-form-service/document/input-type', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(resp => {
                if (resp.data.error === false) {
                    this.types = resp.data.data
                } else {
                    this.$swal.fire("Error ", resp.data.message, "error");
                }
            })
            .catch(error => {
                this.$store.dispatch("setLoading", false);
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                        } else {
                            this.$swal.fire("Request ", error.response.data.message, "error");
                        }
                    }
                } else {
                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                }
            })
            .finally(() => {
                this.$store.dispatch("setLoading", false);
            })
    },
    methods: {
        async saveFormField() {
            // find sequence first
            if (this.shelterForm.find(value => value.position == this.newField.position)) {
                return this.$swal.fire("Error", `Sequence ${this.newField.position} already exist`, "error");
            }

            await this.shelterForm.push({
                ...this.newField
            })

            this.selected = {
                type: {},
                required: {
                    name: 'false',
                    value: false
                }
            }

            this.newField = {
                name_label: "",
                id_type: "",
                type: "",
                position: 1,
                required: false
            }
        },

        async removeFormField(index) {
            this.shelterForm.splice(index, 1)
        },

        async onSearchUser(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "user-service/management/user/list", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false)
                    this.users = resp.data.data
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Purchase Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Purchase Order", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async setSelectedType(value) {

            if (value == null) return;

            this.newField.id_type = value.id
            this.newField.type = value.type
        },

        async onFileChange(event) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                this.icon_content = reader.result;
                this.icon_name = event.target.files[0].name;
            }
        },

        async saveDocument() {

            this.$store.dispatch("setLoading", true);
            axios.post('dynamic-form-service/document/setup/create', {
                    name_form: this.name_form,
                    description: this.description,
                    is_public: this.is_public,
                    user_access: this.user_access,
                    field_form: this.shelterForm,
                    icon_name: this.icon_name,
                    icon_content: this.icon_content
                }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                }).then(resp => {
                    if (resp.data.error === false) {
                        this.clearForm()
                        this.$swal.fire("Success ", resp.data.message, "success");
                    } else {
                        this.$swal.fire("Error ", resp.data.message, "error");
                    }
                })
                .catch(error => {
                    this.$store.dispatch("setLoading", false);
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Error", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                            } else {
                                this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }
                    } else {
                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    }
                })
                .finally(() => {
                    this.$store.dispatch("setLoading", false);
                })
        },

        clearForm() {
            this.selected = {
                type: {},
                required: {
                    name: 'false',
                    value: false
                }
            }

            this.newField = {
                name_label: "",
                id_type: "",
                type: "",
                position: 1,
                required: false
            }

            this.icon_name = ""
            this.icon_content = ""
            this.is_public = false
            this.name_form = ""
            this.description = ""
            this.shelterForm = []
            this.user = []
            this.user_access = null
            this.users = []
        }

    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
