<template>
<div class="container-fluid">

    <div class="row justify-content-center" v-if="dataForm != null">
        <div class="col-md-8">
            <form ref="formAdd" @submit.prevent="handleSubmit">
                <div class="card" style="border: 1px solid #ccc!important; box-shadow: 0 12px 23px 0 rgb(125 129 134 / 39%) !important;">
                    <div class="card-header border-warning d-block d-sm-flex" style="justify-content: center;">
                        <div>
                            <h4 class="text-center">
                                <img :src="dataForm.icon_url" style="object-fit: cover;" width="70" class="mb-2"><br>
                                <span class="text-primary">
                                    {{ dataForm.original_name }}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row">

                            <div class="mb-4">
                                {{ dataForm.description }}
                            </div>

                            <div class="form-group col-md-12" v-for="(value, index) in filterDocSetup" :key="index">
                                <label class="text text-info text-uppercase"> {{ value.name_label }}
                                    <small class="text-danger text-lowercase" v-if="value.required">*required</small>
                                </label>

                                <b-form-datepicker :id="value.id" :required="value.required == 1 ? true : false" class="form-control" v-if="value.type == 'date'" v-model="value.value" />

                                <input type="datetime-local" :required="value.required == 1 ? true : false" class="form-control" :id="value.id" v-else-if="value.type == 'datetime'" v-model="value.value" />

                                <vue-dropify remove-icon="d-none" :required="value.required == 1 ? true : false" @upload="onFileChange($event, value)" v-else-if="value.type == 'image'" accept="image/*" />
                                <!-- <b-form-file :required="value.required == 1 ? true : false" accept="application/pdf, image/*" :id="value.id" v-else-if="value.type == 'image'" @change="onFileChange($event, value)" /> -->

                                <textarea rows="6" :required="value.required == 1 ? true : false" class="form-control" v-else-if="value.type == 'longtext'" v-model="value.value"></textarea>

                                <input :required="value.required == 1 ? true : false" type="time" class="form-control" :id="value.id" v-else-if="value.type == 'time'" v-model="value.value" />

                                <input :required="value.required == 1 ? true : false" type="text" class="form-control" :id="value.id" v-else v-model="value.value" />

                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-outline-success float-right ml-3"> Save Your Form </button>
                        <b-button variant="outline-danger float-right" to="document-on-demand"> Close </b-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import VueDropify from 'vue-dropify';
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: 'FillOutDocument',
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Fill Out Document | %s',
    },
    components: {
        'vue-dropify': VueDropify
    },
    data() {
        return {
            dataForm: null
        }
    },
    computed: {
        filterDocSetup() {
            return this.dataForm.document_setup.map(value => {
                return {
                    ...value,
                    value: '',
                    value_name: null,
                    custom_value: null
                }
            })
        },
    },
    async mounted() {

        if (!this.$route.query.id) {
            return this.$router.push('/')
        }

        this.$store.dispatch("setLoading", true);
        axios.post('dynamic-form-service/document/ready-find', {
                id: this.$route.query.id
            }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(resp => {
                if (resp.data.error === false) {
                    this.dataForm = resp.data.data
                } else {
                    this.$swal.fire("Error ", resp.data.message, "error");
                }
            })
            .catch(error => {
                this.$store.dispatch("setLoading", false);
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                        } else {
                            this.$swal.fire("Error", error.response.data.message, "error");
                        }
                    }
                } else {
                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                }
            })
            .finally(() => {
                this.$store.dispatch("setLoading", false);
            })
    },
    methods: {

        async onFileChange(e, iteration) {
            const files = e[0]
            if (!files) return;

            var reader = new FileReader();
            reader.readAsDataURL(files);
            reader.onload = () => {
                iteration.value = reader.result
                iteration.value_name = e[0].name;
                iteration.custom_value = null
            }
        },

        async handleSubmit() {

            for (let index = 0; index < this.filterDocSetup.length; index++) {
                const element = this.filterDocSetup[index];
                if (element.required == 1) {
                    if (element.value.length == 0) {
                        return this.$swal.fire("Error", `${element.name_label.toUpperCase()} is required`, "error");
                    }
                }
            }

            this.$store.dispatch("setLoading", true);
            axios.post('dynamic-form-service/document/create', {
                    id: this.$route.query.id,
                    data_from: this.filterDocSetup
                }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                }).then(resp => {
                    if (resp.data.error === false) {
                        this.$swal.fire("Success", resp.data.message, "success");
                        this.$router.push("document-on-demand");
                    } else {
                        this.$swal.fire("Error ", resp.data.message, "error");
                    }
                })
                .catch(error => {
                    this.$store.dispatch("setLoading", false);
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Error", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                            } else {
                                this.$swal.fire("Error", error.response.data.message, "error");
                            }
                        }
                    } else {
                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    }
                })
                .finally(() => {
                    this.$store.dispatch("setLoading", false);
                })
        }
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
