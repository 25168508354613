<template>
<div class="container-fluid">
    <MenuApp />
    <div class="row">
        <div class="col-sm-12 col-lg-12 col-xl-8 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header border-0 pb-0 d-sm-flex d-block">
                            <div>
                                <h4 class="card-title mb-1">Orders Summary</h4>
                            </div>
                        </div>
                        <div class="card-body orders-summary">
                            <div class="row">
                                <div class="col-sm-4 mb-4">
                                    <div class="border px-3 py-3 rounded-xl">
                                        <h2 class="fs-32 font-w600 counter">25</h2>
                                        <p class="fs-16 mb-0">On Delivery</p>
                                    </div>
                                </div>
                                <div class="col-sm-4 mb-4">
                                    <div class="border px-3 py-3 rounded-xl">
                                        <h2 class="fs-32 font-w600 counter">60</h2>
                                        <p class="fs-16 mb-0">Delivered</p>
                                    </div>
                                </div>
                                <div class="col-sm-4 mb-4">
                                    <div class="border px-3 py-3 rounded-xl">
                                        <h2 class="fs-32 font-w600 counter">7</h2>
                                        <p class="fs-16 mb-0">Canceled</p>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-timeline-icon">
                                <div class="row align-items-center mx-0">
                                    <div class="col-xl-9 col-lg-8 col-xxl-8 col-sm-8 px-0">
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 fs-14 mr-2 col-4 px-0">Immunities (24%)</p>
                                            <div class="progress mb-0" style="height: 8px; width: 100%">
                                                <div class="progress-bar bg-warning progress-animated" style="width: 85%; height: 8px" role="progressbar">
                                                    <span class="sr-only">60% Complete</span>
                                                </div>
                                            </div>
                                            <span class="pull-right ml-auto col-1 px-0 text-right">25</span>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 fs-14 mr-2 col-4 px-0">Heart Beat (41%)</p>
                                            <div class="progress mb-0" style="height: 8px; width: 100%">
                                                <div class="progress-bar bg-success progress-animated" style="width: 70%; height: 8px" role="progressbar">
                                                    <span class="sr-only">60% Complete</span>
                                                </div>
                                            </div>
                                            <span class="pull-right ml-auto col-1 px-0 text-right">60</span>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="mb-0 fs-14 mr-2 col-4 px-0">Weigth (15%)</p>
                                            <div class="progress mb-0" style="height: 8px; width: 100%">
                                                <div class="progress-bar bg-dark progress-animated" style="width: 30%; height: 8px" role="progressbar">
                                                    <span class="sr-only">60% Complete</span>
                                                </div>
                                            </div>
                                            <span class="pull-right ml-auto col-1 px-0 text-right">07</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-xl-4 col-md-12">
            <div class="row">
                <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Invoice.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{unpaidInvoiceOut}}</span>
                                    </h3>
                                    <p class="mb-0">Unpaid</p>
                                    <small>Invoice Out</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Invoice.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{unpaidInvoiceIn}}</span>
                                    </h3>
                                    <p class="mb-0">Unpaid</p>
                                    <small>Invoice In</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MenuApp from '../../components/MenuApp';
// import axios from "axios";

export default {
    name: "Dashboard",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Dashboard Dynamic Form | %s',
    },
    components: {
        MenuApp
    },
    data() {
        return {
            totalInvoiceOut: 0,
            unpaidInvoiceOut: 0,
            unpaidInvoiceIn: 0,
        };
    },
    async mounted() {
        await this.$store.dispatch("setApp");
    },
};
</script>
