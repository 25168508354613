<template>
<div class="container-fluid">

    <div class="row justify-content-center" v-if="dataDoc != null">
        <div class="col-md-8">
            <form ref="formAdd" @submit.prevent="">
                <div class="card" style="border: 1px solid #ccc!important">
                    <div class="card-header border-warning d-block d-sm-flex" style="justify-content: center">
                        <div>
                            <h4 class="text-center">
                                <img :src="dataDoc.document_form.icon_url" style="object-fit: cover;" width="40" class="mb-2"><br>
                                <span class="text-primary">
                                    {{ dataDoc.document_form.original_name }}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row" v-if="dataDoc.document_value.length > 0">

                            <div class="mb-4">
                                {{ dataDoc.document_form.description }}
                            </div>

                            <div class="form-group col-md-12 mb-4" v-for="(value, index) in dataDoc.document_value" :key="index">
                                <label class="text text-info text-uppercase mr-1"> {{ value.name_label }} :</label>
                                <br />
                                <img width="250" :src="value.value" class="rounded mx-auto d-block" :alt="value.name_label" v-if="value.type == 'image'">
                                <span v-else class="form-control" style="border: 1px solid rgba(60, 60, 60, .26) !important;">
                                    {{ value.value }}
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <img width="150" src="/assets/images/no-icon.png" class="rounded mx-auto d-block" alt="No Data">
                        </div>
                    </div>
                    <div class="card-footer">
                        <b-button variant="outline-danger float-right" to="document-filled-by-personal"> Close </b-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "PersonalPreview",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Preview Document Personal | %s',
    },
    data() {
        return {
            dataDoc: null
        }
    },
    async mounted() {

        if (!this.$route.query.id) {
            return this.$router.push('/')
        }

        this.$store.dispatch("setLoading", true);
        axios.post('dynamic-form-service/document/use-find', {
                id: this.$route.query.id
            }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(resp => {
                if (resp.data.error === false) {
                    this.dataDoc = resp.data.data
                } else {
                    this.$swal.fire("Error ", resp.data.message, "error");
                }
            })
            .catch(error => {
                this.$store.dispatch("setLoading", false);
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                        } else {
                            this.$swal.fire("Error", error.response.data.message, "error");
                        }
                    }
                } else {
                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                }
            })
            .finally(() => {
                this.$store.dispatch("setLoading", false);
            })

    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
